import { template as template_c0ea5b6e03ca468199792bfed035b365 } from "@ember/template-compiler";
const WelcomeHeader = template_c0ea5b6e03ca468199792bfed035b365(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
