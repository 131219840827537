import { template as template_f9e971db05e24752a34ca18789f8bb1f } from "@ember/template-compiler";
const FKLabel = template_f9e971db05e24752a34ca18789f8bb1f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
