import { template as template_ee04a462e79d4759a0b5836920239f71 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ee04a462e79d4759a0b5836920239f71(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
